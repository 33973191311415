import React from 'react'
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'
import Counter from '../components/Counter'
import { ImagesUrl } from '../components/Includes'

const About = () => {
  return (<Template>
<Breadcrumb title='About' />


<div className="about-real-estate content-area-5">
    <div className="container">
        <div className="row">
            <div className="col-xl-5 col-lg-5 col-md-12 col-sm-12 col-xs-12">
                <div className="about-slider-box">
                    <img src={ImagesUrl+'/properties-11.jpg'} alt="Second" className="img-fluid" />
                </div>
            </div>
            <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12 col-xs-12 align-self-center">
                <div className="about-text">
                    <h3>Experience Personalized Real Estate Service</h3>
                    <p>premium2homes & REITs is the global leader in commercial real estate services and investments. We provide clients with services, insights, and data that have been created to span every dimension of the industry. Our team is made up of experienced professionals who are passionate about creating solutions for clients of every size, in every sector, and across every geography. From development and investment to leasing and management, our team is dedicated to providing the best service and advice to our clients.</p>

                    



                    <h3>Explore Our Diverse Investment Portfolio</h3>

                    <p className="mb-0">Our investment properties portfolio includes a wide variety of properties, from single-family homes to apartment complexes and commercial properties. Each property has been carefully vetted by our team to ensure that it offers great potential for investors. Whether you are looking to invest for long-term appreciation or for a steady income stream, we have properties that can meet your needs.</p>
                </div>
            </div>
        </div>
    </div>
</div>

<Counter />



<div className="services-2 content-area-5">
    <div className="container">
        <div className="main-title">
            <h1>What are you looking for?</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
        </div>
        <div className="row wow animated" >
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="service-info-5">
                    <i className="flaticon-apartment"></i>
                    <h4>Apartments</h4>
                    <p>We have the most accurate availability and pricing info to help you find the top deals, best values and true cost of your next apartment.</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="service-info-5">
                    <i className="flaticon-internet"></i>
                    <h4>Houses</h4>
                    <p>A house is a single-unit residential building. It may range in complexity from a rudimentary hut to a complex structure of wood or other material</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="service-info-5">
                    <i className="flaticon-vehicle"></i>
                    <h4>Garages</h4>
                    <p>We are your destination for professional auto detailing supplies, detailing equipment, car care accessories locations.</p>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-12">
                <div className="service-info-5">
                    <i className="flaticon-coins"></i>
                    <h4>Commercial</h4>
                    <p>Care for the Perfectionist! Polishes, waxes. We make it, we guarantee it!</p>
                </div>
            </div>
        </div>
      
    </div>
</div>


  </Template>
  )
}

export default About