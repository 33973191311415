import React from 'react'

const Category = () => {
  return (
  
<div className="categories content-area-8 bg-grea-3">
    <div className="container">
        <div className="main-title">
            <h1>Most Popular Category</h1>
        </div>
        <div className="row wow">
            <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-pad">
                        <div className="category">
                            <div className="category_bg_box cat-1-bg">
                                <div className="category-overlay">
                                    <div className="category-content">
                                        <h3 className="category-title">
                                            <a href="#">Florida</a>
                                        </h3>
                                        <div className="category-subtitle">14 Properties</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-pad">
                        <div className="category">
                            <div className="category_bg_box cat-2-bg">
                                <div className="category-overlay">
                                    <div className="category-content">
                                        <h3 className="category-title">
                                            <a href="#">California</a>
                                        </h3>
                                        <div className="category-subtitle">98 Properties</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-pad">
                        <div className="category">
                            <div className="category_bg_box cat-6-bg">
                                <div className="category-overlay">
                                    <div className="category-content">
                                        <h3 className="category-title">
                                            <a href="#">New York</a>
                                        </h3>
                                        <div className="category-subtitle">98 Properties</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-6 col-pad none-992">
                        <div className="category">
                            <div className="category_bg_box cat-3-bg">
                                <div className="category-overlay">
                                    <div className="category-content">
                                        <h3 className="category-title">
                                            <a href="#">London</a>
                                        </h3>
                                        <div className="category-subtitle">98 Properties</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-pad">
                        <div className="category">
                            <div className="category_bg_box cat-5-bg">
                                <div className="category-overlay">
                                    <div className="category-content">
                                        <h3 className="category-title">
                                            <a href="#">Australia</a>
                                        </h3>
                                        <div className="category-subtitle">98 Properties</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Category