import React, { useEffect, useState } from 'react'

import axios from 'axios'
import {  ImagesUrl, ServerUrl,  config } from './Includes';
const Slider = () => {

    const [content, setContent] = useState([]as any)

const fetchContent = async()=>{
    let url = ServerUrl+'/sliders/display/all'
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
        
        setContent(result.data.data)
        
        }
        })
        .catch((error)=>{
       
        })
      }

   useEffect(()=>{
    fetchContent()
   }, [])   

  return (
   
<div className="banner" id="banner">
    <div id="bannerCarousole" className="carousel slide" data-ride="carousel">
       
        <div className="carousel-inner">


           {content&&content.map((item:any, index:number)=> 
            <div className={`carousel-item banner-max-height item-bg ${index==0?'active':''}`} key={index}>
                <img className="d-block w-100 h-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/sliders/${item.image_url}`} alt="banner" />
                <div className="carousel-caption banner-slider-inner d-flex h-100 text-center">
                    <div className="carousel-content container">
                        <div className="text-center">
                            <div className="text-sections">
                                <h3 className="text-uppercase">{item.title}</h3>
                                <p>Allow us to guide you through the innovative stress free approach in finding your dream Properties.</p>
                            </div>
                            <div className="btn-sections">
                                <a href={item.link} className="btn btn-lg btn-theme">Get Started Now</a>
                                <a href="/about" className="btn btn-lg btn-white-lg-outline">Learn More</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}

        </div>
        <a className="carousel-control-prev" href="#bannerCarousole" role="button" data-slide="prev">
            <span className="slider-mover-left" aria-hidden="true">
                <i className="fa fa-angle-left"></i>
            </span>
        </a>
        <a className="carousel-control-next" href="#bannerCarousole" role="button" data-slide="next">
            <span className="slider-mover-right" aria-hidden="true">
                <i className="fa fa-angle-right"></i>
            </span>
        </a>
    </div>
</div>
  )
}

export default Slider