import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import axios from 'axios'
import {  CURRENCY, FormatNumber, ImagesUrl, ServerUrl,  config } from '../components/Includes';
import { timeSince } from '../components/GlobalFunction';
const LayoutTwo = () => {

    const property  = useSelector((state:any) => state.rootReducer.property);


    const [content, setContent] = useState([]as any)
    const [title, setTitle] = useState('')
    const fetchContent = async()=>{
        let layout ='Product Layout 2';

        let url = ServerUrl+'/collection/show_one/'+layout;
          await  axios.get(url,  config).then((result:any)=>{

            if(result.data.data&&result.data.data.length!==0){
            

                try{

                    const item = JSON.parse(result.data.data.item_list)
                setTitle(result.data.data.title)


            let list:any = []
            item.map((item:any, id:number)=>
            
            
            list.push({
                code:item.code, 
                key:id, 
                publish:getItemName(item.code, 'publish'), 
                headline:getItemName(item.code, 'headline'),
                cover_image:getItemName(item.code, 'cover_image'),
                price:getItemName(item.code, 'price'),
                address:getItemName(item.code, 'address'),
                bedroom:getItemName(item.code, 'bedroom'),
                bathroom:getItemName(item.code, 'bathroom'),
                room:getItemName(item.code, 'room'),
               floor:getItemName(item.code, 'floor'),
               size:getItemName(item.code, 'size'),
               rating:getItemName(item.code, 'rating'),
                garage:getItemName(item.code, 'garage'),
                slug:getItemName(item.code, 'slug'),
                offer_type:getItemName(item.code, 'offer_type'),
                created_at:getItemName(item.code, 'created_at'),
            }))
          


            setContent(list)

                }catch(e){

                }
            
            }
            })
            .catch((error)=>{
           
            })
          }



          const getItemName =(code:string, field:string)=>{
            const result = property&&property.filter((item:any)=>item.code===code)
            const answer = result.length!==0?result[0][field]:''
            return String(answer)
        }

    
       useEffect(()=>{
        fetchContent()
       }, [property])   



  return (
    <div className="featured-properties content-area">
    <div className="container">
        <div className="main-title mt2">
            <h1>{title}</h1>
         
        </div>
        <div className="row">
        {content&&content.map((list:any, index:number)=> <div className="col-lg-4 col-md-6 col-sm-12" key={index}>
                <div className="property-box-4 category">
                    <div className="category_bg_box " style={{backgroundImage:`url(${ImagesUrl+'/property/'+list.cover_image})`}}>
                        <div className="category-overlay">
                            <div className="category-content">
                                <h3>
                                    <a href={'/property/'+list.slug}>{list.headline}</a>
                                </h3>
                                <ul>
                                <li>
                                <i className="flaticon-square"></i> {list.size} sq ft
                            </li>
                            <li>
                                <i className="flaticon-furniture"></i> {list.bedroom} Beds
                            </li>
                            <li>
                                <i className="flaticon-holidays"></i> {list.bathroom} Baths
                            </li>
                            <li>
                                <i className="flaticon-vehicle"></i> {list.garage} Garage
                            </li>
                            <li>
                                <i className="flaticon-window"></i> {list.floor} Floor
                            </li>
                            <li>
                                <i className="flaticon-monitor"></i> {list.room} Room
                            </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>)}
        </div>
    </div>
</div>
  )
}

export default LayoutTwo