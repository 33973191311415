import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import axios from 'axios'
import {  INVESTMENT_URL, ImagesUrl, ServerUrl,  config } from './Includes';

import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
const Header = () => {

    const dispatch = useDispatch()
    const [profile, setProfile] = useState([]as any)

const fetchProfile = async()=>{
    let url = ServerUrl+'/company/profile'
      await  axios.get(url,  config).then((result:any)=>{
        if(result.data.data&&result.data.data.length!==0){
        

            dispatch({
                type:'SET_RECORDS',
                name:'profile',
                data:result.data.data
            })

        setProfile(result.data.data)
        
        }
        })
        .catch((error)=>{
       
        })
      }

   useEffect(()=>{
    fetchProfile()
   }, [])   

  return (<>
  
  <Helmet>
          <title>{profile.business_name}</title>
        <meta name="description" content={profile.page_description} />
       
    
        <meta property="og:title" content={profile.business_name}/> 
        <meta property="og:description" content={profile.page_description} /> 
        <meta property="og:image" content={ImagesUrl+'/'+profile.image_url} />  

        <meta property="og:url" content={profile.website}/> 
      
<meta property="og:type"          content="website" />

    
<link rel="icon" href={ImagesUrl+'/'+profile.image_url} />
        <meta name="twitter:title" content={profile.business_name} /> 
        <meta name="twitter:description" content={profile.page_description}/> 
        <meta name="twitter:image" content={ImagesUrl+'/'+profile.image_url} />  
        <meta name="twitter:url" content={profile.website} /> 

    </Helmet>


    <header className="top-header top-header-bg none-992" id="top-header-2">
    <div className="container">
        <div className="row">
            <div className="col-lg-6 col-md-8 col-sm-7">
                <div className="list-inline">
                    <a href={`tel:${profile.telephone}`}><i className="fa fa-phone"></i>Need Support? {profile.telephone}</a>
                    <a href={`mailto:${profile.email_address}`}><i className="fa fa-envelope"></i>{profile.email_address}</a>
                </div>
            </div>

            <div className="col-lg-6 col-md-4 col-sm-5">
                <ul className="top-social-media pull-right">
                    <li>
                        <a href={INVESTMENT_URL} className="sign-in"><i className="fa fa-sign-in"></i> Login</a>
                    </li>
                    <li>
                        <a href={INVESTMENT_URL+'/register'} className="sign-in"><i className="fa fa-user"></i> Register</a>
                    </li>
                </ul>
            </div>


        </div>
    </div>
</header>


<Navbar
profile={profile}
/>

</>
  )
}

export default Header