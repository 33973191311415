import React from 'react'
import Search from '../components/Search'
import Category from '../layouts/Category'
import Slider from '../components/Slider'
import LayoutOne from '../layouts/LayoutOne'
import Newsletter from '../components/Newsletter'
import Template from '../components/Template'
import Counter from '../components/Counter'
import LayoutThree from '../layouts/LayoutThree'
import LayoutTwo from '../layouts/LayoutTwo'
import LayoutFour from '../layouts/LayoutFour'
import { ImagesUrl } from '../components/Includes'

const HomePage = () => {
  return (<Template>



<Slider />






<Search />

{/* Features properties here */}
<LayoutThree />


<LayoutTwo />

<LayoutOne />



<div className="services-2 content-area-5 bg-grea-3">
    <div className="container">
        <div className="main-title">
            <h1>What are you looking for?</h1>
            <p>Allow us to guide you through the innovative stress free approach in finding your dream Properties</p>
        </div>
        <div className="row wow">
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="services-demo">
                    <img className="img-fluid" src={ImagesUrl+'/properties-11.jpg'} alt="properties" />
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12">
                <div className="service-info-3">
                    <div className="icon">
                        <i className="flaticon-apartment"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Apartments
                        </h3>
                        <p>We have the most accurate availability and pricing info to help you find the top deals, best values and true cost of your next apartment.</p>
                    </div>
                </div>
                <div className="service-info-3">
                    <div className="icon">
                        <i className="flaticon-internet"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Houses
                        </h3>
                        <p>
A house is a single-unit residential building. It may range in complexity from a rudimentary hut to a complex structure of wood or other material.</p>
                    </div>
                </div>
                <div className="service-info-3">
                    <div className="icon">
                        <i className="flaticon-vehicle"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Garages
                        </h3>
                        <p>We are your destination for professional auto detailing supplies, detailing equipment, car care accessories locations.</p>
                    </div>
                </div>
                <div className="service-info-3 mb-0">
                    <div className="icon">
                        <i className="flaticon-coins"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Commercial
                        </h3>
                        <p>Care for the Perfectionist! Polishes, waxes. We make it, we guarantee it!</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<Newsletter />

{/* Recent properties header */}


<LayoutFour />
<Category />



<Counter />





</Template>
  )
}

export default HomePage