
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'
import axios from 'axios'
import { ServerUrl, config } from '../components/Includes';
import { useDispatch, useSelector } from 'react-redux';
const Contact = () => {

    const profile  = useSelector((state:any) => state.rootReducer.profile);
    const [notice, setNotice] = useState({
        isLoading : false
	});
    const [comment, setComment]= useState({
        fullname:'',
        telephone:'',
        email:'',
        property_code:'',
        message:'Hello, I am interested in ...',
        how_you_hear:'',
        num1:'',
        num2:'',
        answer:'',
        puzzle:''
     })

     const handleCall = ()=>{
        window.open('tel:'+profile.telephone)
     }
   const handleReset=()=>{
    var num1 = Math.floor(Math.random() * 9)
    var num2 = Math.floor(Math.random() * 9)
    var answer = num1+num2
   
       setComment({
        fullname:'',
        telephone:'',
        email:'',
        property_code:'',
        message:'Hello, I am interested in ...',
        how_you_hear:'',
        num1:String(num1), 
        num2:String(num2), 
        answer:answer.toString(),
        puzzle:''
       })
   }
 const [errors, setErrors] = useState({
    message:'',
    fullname:'',
    telephone:'',
    email:'',
    how_you_hear:'',
        puzzle:'',
    errorMessage:'',
    successMessage:''
 })

     const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
        setComment({...comment, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setComment({...comment, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const generatePuzzle =()=>{
        var num1 = Math.floor(Math.random() * 9)
        var num2 = Math.floor(Math.random() * 9)
        var answer = num1+num2
        setComment({...comment, num1:String(num1), num2:String(num2), answer:answer.toString()})
    
} 

const handleChangeArea = (event:ChangeEvent<HTMLTextAreaElement>) =>{	
    let {name, value} = event.target;	
    setComment({...comment, [name] : value });
    setErrors({...errors, [name]:''});
 }


    const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        let formIsValid = true;
        let errorLog ={...errors}
			let msg ='This field is required';

            let email = comment.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
            if(!email){
                formIsValid = false;
                errorLog.email ='Please enter valid email address';
            }


      if(!comment.fullname){ 
        formIsValid = false;
            errorLog.fullname =msg
		}  
       
        if(!comment.telephone){ 
            formIsValid = false;
                errorLog.telephone =msg
            }  


        if(!comment.message){ 
            formIsValid = false;
            errorLog.message =msg
		} 



        if(!comment.puzzle){ 
            formIsValid = false;
            errorLog.puzzle=msg

            setErrors({...errors, puzzle:msg})
		}else if(Number(comment.puzzle) !== Number(comment.answer)){
            formIsValid = false;
            errorLog.puzzle ='Answer provided to puzzle is not correct';
          
        }

        if(!formIsValid){
            setErrors(errorLog)
        }
        


            if (formIsValid) {

               setNotice({...notice,  isLoading: true})     

       
            const fd = new FormData();
        Object.entries(comment).forEach(([key, value]) => {
                fd.append(key, String(value));
            }); 


 let url = ServerUrl+'/contact/add';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
            setErrors({...errors, successMessage: response.data.message})
         
       setTimeout(()=>{
        window.location.reload()
       }, 3000)
                  } else{

                    
         setErrors({...errors, errorMessage: response.data})
                   
                  }   
              })
              .catch((error)=>{
                 setErrors({...errors, errorMessage:error.message})
              }).finally(()=>{
                setNotice({...notice,  isLoading: false})
                handleReset()  
              }) 
        }
    }

   
    useEffect(()=>{
        generatePuzzle();
        }, [])



  return (<Template>
<Breadcrumb title='Contact' />

<div className="contact-2 content-area-5">
    <div className="container">
        <div className="main-title">
            <h1 className="mb-10">Contact us</h1>
    
        </div>
        <div className="contact-info">
            <div className="row">
                <div className="col-md-3 col-sm-6 mrg-btn-50">
                    <i className="flaticon-location"></i>
                    <p>Office Address</p>
                    <strong>{profile.address}</strong>
                </div>
                <div className="col-md-3 col-sm-6 mrg-btn-50">
                    <i className="flaticon-technology-1"></i>
                    <p>Phone Number</p>
                    <strong>{profile.telephone}</strong>
                </div>
                <div className="col-md-3 col-sm-6 mrg-btn-50">
                    <i className="flaticon-envelope"></i>
                    <p>Email Address</p>
                    <strong>{profile.email_address}</strong>
                </div>
                <div className="col-md-3 col-sm-6 mrg-btn-50">
                    <i className="flaticon-globe"></i>
                    <p>Web</p>
                    <strong>{profile.website}</strong>
                </div>
            </div>
        </div>

        <form action="#"  >
            <div className="row">
                <div className="col-lg-8">
                    <div className="row">


{errors.successMessage?
    <div className="alert alert-success" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <strong>Success!</strong> {errors.successMessage}
                </div>:[]}

                {errors.errorMessage?
    <div className="alert alert-danger" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <strong>Errors!</strong> {errors.errorMessage}
                </div>:[]}

                        <div className="col-md-6">
                            <div className="form-group name">
                                <input type="text" name='fullname' placeholder='Full Name*'   className={errors.fullname ? ' form-control formerror' : 'form-control '} value={comment.fullname} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group email">
                                <input type="email" name='email' placeholder='Email Address*' className={errors.email ? ' form-control formerror' : 'form-control '} value={comment.email} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group subject">
                                <input type="text" name='how_you_hear' placeholder='Subject' className={errors.how_you_hear ? ' form-control formerror' : 'form-control '} value={comment.how_you_hear} onChange={handleChange} required />
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group number">
                                <input type="text" name='telephone' placeholder='Phone Number*' className={errors.telephone ? ' form-control formerror' : 'form-control '} value={comment.telephone} onChange={handleChange} required/>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="form-group message">
                                <textarea   name="message" placeholder="Write message" 
        value={comment.message} onChange={handleChangeArea} className={errors.message ? ' formerror form-control' : 'form-control'} required></textarea>
                            </div>
                        </div>

                        <div className="col-md-12 mb-3">

                        <input type="text" className={errors.puzzle ? ' form-control formerror' : 'form-control '}  id="puzzle"   name="puzzle" role={'presentation'}  autoComplete="off" value={comment.puzzle} placeholder={comment.num1 + ' + '+ comment.num2 + '  =?' } onChange={handleChange} />
                        </div>


                        <div className="col-md-12">
                            <div className="send-btn text-center">

                            {notice.isLoading? <button className="btn btn-md button-theme" type="button" disabled><span className="spinner-border spinner-border-sm mr-2" ></span>Loading...</button>:
                                <button type="submit" className="btn btn-md button-theme">Send Message</button>}


                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4">
                    <div className="opening-hours">
                        <h3>Opening Hours</h3>
                        <ul className="list-style-none">
                            <li><strong>Sunday</strong> <span className="text-red"> closed</span></li>
                            <li><strong>Monday</strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Tuesday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Wednesday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Thursday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Friday </strong> <span> 10 AM - 8 PM</span></li>
                            <li><strong>Saturday </strong> <span> 10 AM - 8 PM</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

  </Template>
  )
}

export default Contact