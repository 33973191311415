import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import axios from 'axios'
import {  CURRENCY, FormatNumber, ImagesUrl, ServerUrl,  config } from '../components/Includes';
import { timeSince } from '../components/GlobalFunction';
const Similar =({similar}:{similar:any})=>{


    const property  = useSelector((state:any) => state.rootReducer.property);


    const [content, setContent] = useState([]as any)

    const fetchContent = async()=>{

            if(similar!=='[]'){
            

                try{

                    const item = JSON.parse(similar)


            let list:any = []
            item.map((item:any, id:number)=>
            
            
            list.push({
                code:item.code, 
                key:id, 
                publish:getItemName(item.code, 'publish'), 
                headline:getItemName(item.code, 'headline'),
                cover_image:getItemName(item.code, 'cover_image'),
                price:getItemName(item.code, 'price'),
                address:getItemName(item.code, 'address'),
                bedroom:getItemName(item.code, 'bedroom'),
                bathroom:getItemName(item.code, 'bathroom'),
                room:getItemName(item.code, 'room'),
               floor:getItemName(item.code, 'floor'),
               size:getItemName(item.code, 'size'),
               rating:getItemName(item.code, 'rating'),
                garage:getItemName(item.code, 'garage'),
                slug:getItemName(item.code, 'slug'),
                offer_type:getItemName(item.code, 'offer_type'),
                created_at:getItemName(item.code, 'created_at'),
            }))
          


            setContent(list)

                }catch(e){

                }
            
            }
          }



          const getItemName =(code:string, field:string)=>{
            const result = property&&property.filter((item:any)=>item.code===code)
            const answer = result.length!==0?result[0][field]:''
            return String(answer)
        }

    
       useEffect(()=>{
        fetchContent()
       }, [property])   

    return  <>

<div className="row">


{content&&content.map((list:any, index:number)=> <div key={index} className="col-md-6">
        <div className="property-box-3">
            <div className="property-thumbnail">
                <a href={'/property/'+list.slug} className="property-img">
                <div className={list.offer_type==='For Rent'?"tag-2 bg-active":'tag-2'}>For Rent</div>
                    <img className="d-block w-100" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.cover_image}`}  alt="properties" />
                    <div className="facilities-list">
                    <ul>
                                    <li>
                                <i className="flaticon-square"></i> {list.size} sq ft
                            </li>
                            <li>
                                <i className="flaticon-furniture"></i> {list.bedroom} Beds
                            </li>
                            <li>
                                <i className="flaticon-holidays"></i> {list.bathroom} Baths
                            </li>
                                    </ul>
                    </div>
                </a>
            </div>
            <div className="details">
                <div className="top">
                    <h1 className="title">
                        <a href={'/property/'+list.slug}>{list.headline}</a>
                        <span>{CURRENCY+FormatNumber(list.price)}</span>
                    </h1>
                    <div className="location">
                        <a href={'/property/'+list.slug} tabIndex={0}>
                            <i className="fa fa-map-marker"></i>{list.address}
                        </a>
                    </div>
                </div>
                <div className="footer clearfix">
                    <div className="pull-left days">
                        <p><i className="flaticon-time"></i> {timeSince(new Date(list.created_at))}</p>
                    </div>
                    <ul className="pull-right">
                        <li><a href="#" tabIndex={0}><i className="flaticon-favorite"></i></a></li>
                        <li><a href="#" tabIndex={0}><i className="flaticon-multimedia"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>)}

    
                                    </div>

    </>
}

export default Similar