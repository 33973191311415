import React, { Children, useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'

import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { ServerUrl, config } from './Includes';

const  Template = (props:{children?:React.ReactNode})=> {
  const dispatch = useDispatch()

  


             const fetchAmenity = async ()=>{

              let url = ServerUrl+'/amenity/view'
                  const {data} = await axios.get(url, config)
               
                  dispatch({
                      type:'SET_RECORDS',
                      name:'amenity',
                      data:data.data
                  })
          }

  const fetchProperty = async ()=>{

    let url = ServerUrl+'/property/display/all'
        const {data} = await axios.get(url, config)
     
        dispatch({
            type:'SET_RECORDS',
            name:'property',
            data:data.data
        })
}

const fetchCategory = async ()=>{

  let url = ServerUrl+'/category/display_counter'
      const {data} = await axios.get(url, config)
   
      dispatch({
          type:'SET_RECORDS',
          name:'category',
          data:data.data
      })
}


useEffect(()=>{
  fetchAmenity()
  fetchProperty()
  fetchCategory()
},[])

  return (
    <>
    <Header />

{props.children}


    <Footer />
    </>
  )
}

export default Template