import React from 'react'

const Breadcrumb = (props:any) => {
  return (
    <div className="sub-banner">
    <div className="container">
        <div className="page-name">
            <h1>{props.title}</h1>
            <ul>
                <li><a href="/">Home</a></li>
                <li><span>/</span>{props.title}</li>
            </ul>
        </div>
    </div>
</div>
  )
}

export default Breadcrumb