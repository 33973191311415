import React from 'react'

const Newsletter = () => {
  return (
    <div className="our-newslatters-2">
    <div className="container">
        <div className="row inner">
            <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="intro-text">
                    <h3>Subscribe to Newsletter</h3>
                    <p>Subscribe to get update and information. Don't worry, we won't send spam!</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="subscribe-box">
                    <form className="form-inline" >
                        <input type="text" className="form-control mb-sm-0"  placeholder="Email Address" required />
                        <button type="submit" className="btn"><i className="fa fa-paper-plane"></i></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Newsletter