import React from 'react'
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'
import { INVESTMENT_URL } from '../components/Includes'

const Investment = () => {
  return (
    <Template>

<Breadcrumb title='Investment' />




<div className="pricing-tables-2 content-area">
    <div className="container">
        <div className="main-title">
            <h1>Real Estate Investment Plan</h1>
           
        </div>
        <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="pricing-2">
                    <div className="title">Bungallow</div>
                    <div className="price-for-user">
                        <div className="price text-color"><sup>$</sup><span className="dolar">100,000</span>- <br/>
                        <sup>$</sup><span className="dolar">20,000,000</span>
                        </div>
                    </div>


                    <div className="content">
                        <ul>
                        <li>Earnings</li>
                            <li>Capital back after 120days</li>
                            <li>Referal Bonus 5%</li>
                            <li>Earning (3.5%) Everyday for 1 times (s)</li>
                            <li>$2 registration bonus</li>
                        </ul>
                    </div>
                    <div className="button"><a href={INVESTMENT_URL+'/register'} className="btn btn-outline pricing-btn">Get started</a></div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="pricing-2">
                    <div className="title">Villa</div>
                   
                   
                  


                    <div className="price-for-user">
                        <div className="price text-color"><sup>$</sup><span className="dolar">2,500</span>- <br/>
                        <sup>$</sup><span className="dolar">10,000,000</span>
                        </div>
                    </div>

                    <div className="content">
                        <ul>
                        <li>Earnings</li>
                            <li>Capital back after 90days</li>
                            <li>Referal Bonus 5%</li>
                            <li>Earning (2.5%) Everyday for 1 times (s)</li>
                            <li>$2 registration bonus</li>
                        </ul>
                    </div>
                    <div className="button"><a href={INVESTMENT_URL+'/register'}className="btn btn-outline pricing-btn">Get started</a></div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="pricing-2">
                    <div className="title">Mansion</div>
                   


                    <div className="price-for-user">
                        <div className="price text-color"><sup>$</sup><span className="dolar">2,999</span>- <br/>
                        <sup>$</sup><span className="dolar">10,000,000</span>
                        </div>
                    </div>


                    <div className="content">
                        <ul>
                        <li>Earnings</li>
                            <li>Capital back after 60days</li>
                            <li>Referal Bonus 15%</li>
                            <li>Earning (1.5%) Everyday for 1 times (s)</li>
                            <li>$2 registration bonus</li>
                        </ul>
                    </div>
                    <div className="button"><a href={INVESTMENT_URL+'/register'} className="btn btn-outline pricing-btn">Get started</a></div>
                </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="pricing-2">
                    <div className="title">Duplex</div>
                    <div className="price-for-user">
                        <div className="price text-color"><sup>$</sup><span className="dolar">50</span>- <br/>
                        <sup>$</sup><span className="dolar">1,999</span>
                        </div>
                    </div>

                    <div className="content">
                        <ul>
                        <li>Earnings</li>
                            <li>Capital back after 30days</li>
                            <li>Referal Bonus 5%</li>
                            <li>Earning (0.5%) Everyday for 1 times (s)</li>
                            <li>$2 registration bonus</li>
                        </ul>
                    </div>
                    <div className="button"><a href={INVESTMENT_URL+'/register'} className="btn btn-outline pricing-btn">Get started</a></div>
                </div>
            </div>
        </div>
    </div>
</div>
</Template>
  )
}

export default Investment