import React, { useEffect, useState } from 'react'
import Template from '../components/Template'
import Similar from '../layouts/SimilarProperties'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import {  CURRENCY, FormatNumber, ImagesUrl, ServerUrl,  config } from '../components/Includes';
import { timeSince } from '../components/GlobalFunction'

import { useSelector } from 'react-redux'
import Interested from './Interested'
const Details = () => {
    let params = useParams()
    const [content, setContent] = useState([]as any)
    const [amenity, setAmenity] = useState([] as any)
    const [image, setImage] = useState([] as any)
    const category  = useSelector((state:any) => state.rootReducer.category);
    const features  = useSelector((state:any) => state.rootReducer.amenity);



    const fetchContent = async()=>{
        let url = ServerUrl+'/property/show/one/'+params.code
          await  axios.get(url,  config).then((result:any)=>{
            if(result.data.data&&result.data.data.length!==0){
                try{
            setContent(result.data.data)
            let selectfeature = JSON.parse(result.data.data.features)
            let images = JSON.parse(result.data.data.image_list)


            var allAmenity= [];
            var options =features
            for (var i = 0, l = options.length; i < l; i++) {

                if(selectfeature.includes(options[i].id)){

              allAmenity.push({
                id:options[i].id,
                title:options[i].title,
                icon:options[i].icon,

              });
            }
            }
            setAmenity(allAmenity)

            setImage(images)
        }catch(e){

        }
            }else{
                window.open('/', '_self');
            }
            })
            .catch((error)=>{
           
            })
          }
    


          const handleChangeCover =(cover:string)=>{

            setContent({...content, cover_image:cover})

          }
       useEffect(()=>{
        fetchContent()
       }, [])  

  return (<Template>
    <div className="properties-details-page content-area-7">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-12 col-xs-12">
                <div className="properties-details-section">
                    <div id="propertiesDetailsSlider" className="carousel properties-details-sliders slide mb-40">
                        <div className="heading-properties-2">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="pull-left">
                                        <h3>{content.headline}</h3>
                                        <p><i className="fa fa-map-marker"></i> {content.address}</p>
                                    </div>
                                    <div className="pull-right">
                                        <h3><span className="text-right">{CURRENCY+FormatNumber(content.price)}</span></h3>
                                        <p><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star"></i><i className="fa fa-star-half-o"></i></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-inner">
                       <div className="active item carousel-item" data-slide-number={0} >
                                <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${content.cover_image}`} className="img-fluid" alt="slider-properties" />
                            </div>

                        </div>

                        <ul className="carousel-indicators smail-properties list-inline nav nav-justified">
                        {image&&image.map((list:any, index:number)=><li className="list-inline-item active" key={index}>
                                <a onClick={()=>handleChangeCover(list.imageUrl)} className="selected" data-slide-to="0" data-target="#propertiesDetailsSlider">
                                    <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.imageUrl}`}  className="img-fluid h-200" alt="properties-small" />
                                </a>
                            </li>)}
                        </ul>
                    </div>

                    <div className="widget-2 sidebar advanced-search-2">
                        <h3 className="sidebar-title">Advanced Search</h3>
                        <div className="s-border"></div>
                        <div className="m-border"></div>
                        <form method="GET">
                            <div className="form-group">
                                <select className="selectpicker search-fields" name="property-sdtatus">
                                    <option>Property Status</option>
                                    <option>For Sale</option>
                                    <option>For Rent</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <select className="selectpicker search-fields" name="property-type">
                                    <option>Property Type</option>
                                    <option>Apartments</option>
                                    <option>Houses</option>
                                    <option>Commercial</option>
                                    <option>Garages</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <select className="selectpicker search-fields" name="commercial">
                                    <option>Commercial</option>
                                    <option>Residential</option>
                                    <option>Land</option>
                                    <option>Hotels</option>
                                </select>
                            </div>
                            <div className="form-group">
                                <select className="selectpicker search-fields" name="location">
                                    <option>location</option>
                                    <option>New York</option>
                                    <option>Bangladesh</option>
                                    <option>India</option>
                                    <option>Canada</option>
                                </select>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="selectpicker search-fields" name="bedrooms">
                                            <option>Bedrooms</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="selectpicker search-fields" name="bathroom">
                                            <option>Bathroom</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="selectpicker search-fields" name="balcony">
                                            <option>Balcony</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <select className="selectpicker search-fields" name="garage">
                                            <option>Garage</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="range-slider">
                                <label>Area</label>
                                <div data-min="0" data-max="10000" data-min-name="min_area" data-max-name="max_area" data-unit="Sq ft" className="range-slider-ui ui-slider" aria-disabled="false"></div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="range-slider">
                                <label>Price</label>
                                <div data-min="0" data-max="150000"  data-min-name="min_price" data-max-name="max_price" data-unit="USD" className="range-slider-ui ui-slider" aria-disabled="false"></div>
                                <div className="clearfix"></div>
                            </div>
                            <div className="form-group mb-0">
                                <button className="search-button">Search</button>
                            </div>
                        </form>
                    </div>






                    <div className="tabbing tabbing-box tb-2 mb-40">
                        <ul className="nav nav-tabs" id="carTab" role="tablist">
                            <li className="nav-item">
                                <a className="nav-link active show" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="false">Description</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">Floor Plans</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="true">Details</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="4-tab" data-toggle="tab" href="#4" role="tab" aria-controls="4" aria-selected="true">Video</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="5-tab" data-toggle="tab" href="#5" role="tab" aria-controls="5" aria-selected="true">Location</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" id="6-tab" data-toggle="tab" href="#6" role="tab" aria-controls="6" aria-selected="true">Similar Properties</a>
                            </li>
                        </ul>
                        <div className="tab-content" id="carTabContent">
                            <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="one-tab">
                                <div className="properties-description mb-50">
                                    <h3 className="heading-2">
                                        Description
                                    </h3>
                                    <p dangerouslySetInnerHTML={{__html:content.description}}></p>
                                </div>
                            </div>
                            <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                                <div className="floor-plans mb-50">
                                    <h3 className="heading-2">Floor Plans</h3>
                                    <table>
                                        <tbody><tr>
                                            <td><strong>Size</strong></td>
                                            <td><strong>Rooms</strong></td>
                                            <td><strong>Bathrooms</strong></td>
                                            <td><strong>Garage</strong></td>
                                        </tr>
                                        <tr>
                                            <td>{content.size}</td>
                                            <td>{content.room}</td>
                                            <td>{content.bathroom}</td>
                                            <td>{content.garage}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="tab-pane fade " id="three" role="tabpanel" aria-labelledby="three-tab">
                                <div className="property-details mb-40">
                                    <h3 className="heading-2">Property Details</h3>
                                    <div className="row">
                                        <div className="col-md-4 col-sm-6">
                                            <ul>
                                                <li>
                                                    <strong>Property Id:</strong>{content.reference_no}
                                                </li>
                                                <li>
                                                    <strong>Price:</strong>{CURRENCY+FormatNumber(content.price)}
                                                </li>
                                                <li>
                                                    <strong>Property Type:</strong>{content.property_status}
                                                </li>
                                                <li>
                                                    <strong>Bathrooms:</strong>{content.bathroom}
                                                </li>
                                                <li>
                                                    <strong>Bedrooms:</strong>{content.bedroom}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <ul>
                                                <li>
                                                    <strong>Property Lot Size:</strong>{content.size}
                                                </li>
                                                <li>
                                                    <strong>Room:</strong>{content.room}
                                                </li>
                                                <li>
                                                    <strong>Floor:</strong>{content.floor}
                                                </li>
                                                <li>
                                                    <strong>Available From:</strong>{timeSince(new Date(content.created_at))}
                                                </li>
                                                <li>
                                                    <strong>Garages:</strong>{content.garage}
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-sm-6">
                                            <ul>
                                                <li>
                                                    <strong>Property Status:</strong>{content.property_status}
                                                </li>
                                                <li>
                                                    <strong>City:</strong>{content.city}
                                                </li>
                                                <li>
                                                    <strong>Province:</strong>{content.province}
                                                </li>
                                                <li>
                                                    <strong>Country:</strong>{content.country}
                                                </li>
                                                <li>
                                                    <strong>Address: </strong>{content.address}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tab-pane fade " id="4" role="tabpanel" aria-labelledby="4-tab">
                                <div className="inside-properties mb-50">
                                    <h3 className="heading-2">
                                        Property Video
                                    </h3>

                                    {content.youtube!==null?
                                    <iframe src={content.youtube} ></iframe>:[]}
                                </div>
                            </div>
                            <div className="tab-pane fade " id="5" role="tabpanel" aria-labelledby="5-tab">
                                <div className="location mb-50">
                                    <div className="map">
                                        <h3 className="heading-2">Property Location</h3>
                                        <div className="contact-map">

                                        <iframe src={content.google_map} width="600" height="450" 
                                        allowFullScreen={true} loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>



                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade " id="6" role="tabpanel" aria-labelledby="6-tab">
                                <div className="similar-properties mb-30">
                                    <h3 className="heading-2">Similar Properties</h3>

                                        <Similar 
                                        
                                        similar={content.related_product}
                                        />




                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="properties-amenities mb-40">
                        <h3 className="heading-2">
                            Features
                        </h3>
                       


                                <ul className="row amenities">
                                {amenity&&amenity.map((item:any, index:number)=>

                                    <li key={index} className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                        <i className={item.icon}></i>{item.title}
                                    </li>)}
                                </ul>

                    </div>

                </div>
            </div>
            <div className="col-lg-4 col-md-12">
                <div className="sidebar-right">




            <Interested slug={params.code} />







                    <div className="posts-by-category widget">
                        <h3 className="sidebar-title">Category</h3>
                        <div className="s-border"></div>
                        <div className="m-border"></div>
                        <ul className="list-unstyled list-cat">
                            {category&&category.map((item:any, index:number)=>
                            <li key={index}><a href="#!">{item.title} <span>({item.total})</span></a></li>)}
                            
                        </ul>
                    </div>






                    <div className="social-media-box widget clearfix">
                        <h3 className="sidebar-title">Social Media</h3>
                        <div className="s-border"></div>
                        <div className="m-border"></div>
                        <ul className="social-list">
                            <li>
                                <a href="#" className="facebook-bg">
                                    <i className="fa fa-facebook"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="twitter-bg">
                                    <i className="fa fa-twitter"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="google-bg">
                                    <i className="fa fa-google"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="linkedin-bg">
                                    <i className="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <li>
                                <a href="#" className="pinterest-bg">
                                    <i className="fa fa-pinterest"></i>
                                </a>
                            </li>
                        </ul>
                    </div>




                </div>
            </div>
        </div>
    </div>
</div>
</Template>
  )
}

export default Details