import React from 'react'
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'

import { useSelector } from 'react-redux'
import { ImagesUrl } from '../components/Includes'
const Gallery = () => {
    const property  = useSelector((state:any) => state.rootReducer.property);

  return (<Template>

<Breadcrumb title='Gallery' />

<div className="photo-gallery content-area">
    <div className="container">
        <div className="main-title">
            <h1>Photo Gallery</h1>
          
        </div>
        <div className="row">

          {property&&property.map((list:any, index:number)=>  <div className="col-md-4 col-sm-6" key={index}>
                <div className="portfolio-item">
                    <a href={'/property/'+list.slug} title={list.headline}>
                        <img onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.cover_image}`}  alt="gallery-photo" className="img-fluid" />
                    </a>
                    <div className="portfolio-content">
                        <div className="portfolio-content-inner">
                            <p>{list.headline}</p>
                        </div>
                    </div>
                </div>
            </div>)}


        </div>
    </div>
</div>

  </Template>
  )
}

export default Gallery