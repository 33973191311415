
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import HomePage from "./property/home";
import Details from "./property/Details";
import SearchResult from "./property/SearchResult";
import Services from "./property/Services";
import Contact from "./property/Contact";
import Faq from "./property/Faq";
import About from "./property/About";
import Error404 from "./property/Error404";
import Property from "./property/Property";
import Gallery from "./property/Gallery";
import Investment from "./property/Investment";



function App() {

   
  return (
    <>
    <Router>
          <Routes>
         <Route  path="/" element={<HomePage />} />
         <Route  path="/property/:code" element={<Details />} />
         <Route  path="/search" element={<SearchResult  />} />
         <Route  path="/services" element={<Services  />} />
         <Route  path="/contact" element={<Contact  />} />
         <Route  path="/about" element={<About  />} />
         <Route  path="/faq" element={<Faq  />} />
         <Route  path="/property" element={<Property  />} />
         <Route  path="/gallery" element={<Gallery  />} />
         <Route  path="/investment" element={<Investment  />} />
         <Route  path="*" element={<Error404 />} />
         </Routes>
      
    </Router>

    
</>
  );
}

export default App;
