import React from 'react'

import { useDispatch, useSelector } from 'react-redux';
const Footer = () => {

    const profile  = useSelector((state:any) => state.rootReducer.profile);

  return (
    <> 
    
    
    
<div className="our-newslatters-3 clearfix">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="intro-text">
                    <h3>Do You Have Questions ?</h3>
                    <p>We'll help you by recommending best Apartment.</p>
                </div>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12">
                 <div className="subscribe-box">
                     <a href="/contact" className="contact-us-today btn">Contact Us Today</a>
                 </div>
            </div>
        </div>
    </div>
</div>



    <footer className="footer">
    <div className="container footer-inner">
        <div className="row">
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6">
                <div className="footer-item">
                    <h4>Contact Us</h4>
                    <ul className="contact-info">
                        <li>{profile.address}
                        </li>
                        <li>
                            <a href={`mailto:${profile.email_address}`} >{profile.email_address}</a>
                        </li>
                        <li>
                            <a href={`tel:${profile.telephone}`}>{profile.telephone}</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div className="footer-item">
                    <h4>Properties Types</h4>
                    <ul className="links">
                        <li>
                            <a>Apartment</a>
                        </li>
                        <li>
                            <a>Restaurant</a>
                        </li>
                        <li>
                            <a>My Houses</a>
                        </li>
                        <li>
                           <a>Villa & Condo</a>
                        </li>
                        <li>
                            <a>Family House</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-2 col-lg-2 col-md-6 col-sm-6">
                <div className="footer-item">
                    <h4>Quick Links</h4>
                    <ul className="links">
                        <li>
                            <a href='/about'>About Us</a>
                        </li>
                        <li>
                            <a href='/services'>Services</a>
                        </li>
                        <li>
                            <a href='/property'>Properties Details</a>
                        </li>
                        <li>
                            <a href='/faq'>Faq</a>
                        </li>
                        <li>
                            <a href='/gallery'> Gallery</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-4 col-lg-3 col-md-6 col-sm-6">
                <div className="footer-item clearfix">
                    <h4>Subscribe</h4>
                    <div className="subscribe-box-2">
                        <form className="form-inline" >
                            <input type="text" className="form-control mb-sm-0" id="inlineFormInputName4" placeholder="Your Email" required />
                            <button type="submit" className="btn"><i className="fa fa-paper-plane"></i></button>
                        </form>
                    </div>
                    <ul className="social-list clearfix">
                        <li><a href="#" className="facebook-bg"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#" className="twitter-bg"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#" className="google-bg"><i className="fa fa-google-plus"></i></a></li>
                        <li><a href="#" className="rss-bg"><i className="fa fa-rss"></i></a></li>
                        <li><a href="#" className="linkedin-bg"><i className="fa fa-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>

<div className="sub-footer">
    <div className="container">
        <div className="row">
            <div className="col-xl-12">
                <p className="copy">© 2021 <a href="/">{profile.business_name}</a> All Right Reserved.</p>
            </div>
        </div>
    </div>
</div>

<div id="full-page-search">
    <button type="button" className="close">×</button>
    <form action="https://storage.googleapis.com/theme-vessel-items/checking-sites/housy-html/HTML/index.html#">
        <input type="search" value="" placeholder="type keyword(s) here" />
        <button type="submit" className="btn btn-sm button-theme">Search</button>
    </form>
</div>
</>
  )
}

export default Footer