import React from 'react'

const Search = () => {
  return ( <>
  
  <div className="search-section ss2 search-area-2 bg-grea">
    <ul className="nav nav-tabs" id="carTab" role="tablist">
        <li className="nav-item">
            <a className="nav-link active show" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="false">For Rent</a>
        </li>
        <li className="nav-item">
            <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">For Sale</a>
        </li>
    </ul>
    <div className="tab-content" id="carTabContent">
        <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="one-tab">
            <div className="s">
                <div className="container">
                    <div className="search-section-area">
                        <div className="search-area-inner">
                <div className="search-contents">
                    <form method="GET" action='/search'>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <div className="form-group email">
                                        <input type="email" name="email" className="form-control search-fields" placeholder="Enter Keyword" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="property-status">
                                        <option>Property Status</option>
                                        <option>For Sale</option>
                                        <option>For Rent</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="property-types">
                                        <option>Property Types</option>
                                        <option>Apartments</option>
                                        <option>Houses</option>
                                        <option>Commercial</option>
                                        <option>Garages</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="location">
                                        <option>Location</option>
                                        <option>United States</option>
                                        <option>United Kingdom</option>
                                        <option>American Samoa</option>
                                        <option>Belgium</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="max-area">
                                        <option>Bedrooms</option>
                                        <option>2400</option>
                                        <option>2800</option>
                                        <option>3200</option>
                                        <option>3600</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="min-area">
                                        <option>Bathrooms</option>
                                        <option>2400</option>
                                        <option>2800</option>
                                        <option>3200</option>
                                        <option>3600</option>
                                    </select>
                                </div>
                            </div>


                         
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <button className="search-button">Search</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</div>





<div className="tab-pane" id="two" role="tabpanel" aria-labelledby="two-tab">
<div className="s">
    <div className="container">
        <div className="search-section-area">
            <div className="search-area-inner">
                <div className="search-contents">
                    <form method="GET" action='/search'>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <div className="form-group email">
                                        <input type="email" name="email" className="form-control search-fields" placeholder="Enter Keyword" />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="property-status">
                                        <option>Property Status</option>
                                        <option>For Sale</option>
                                        <option>For Rent</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="property-types">
                                        <option>Property Types</option>
                                        <option>Apartments</option>
                                        <option>Houses</option>
                                        <option>Commercial</option>
                                        <option>Garages</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="location">
                                        <option>Location</option>
                                        <option>United States</option>
                                        <option>United Kingdom</option>
                                        <option>American Samoa</option>
                                        <option>Belgium</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="max-area">
                                        <option>Bedrooms</option>
                                        <option>2400</option>
                                        <option>2800</option>
                                        <option>3200</option>
                                        <option>3600</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <select className="selectpicker search-fields" name="min-area">
                                        <option>Bathrooms</option>
                                        <option>2400</option>
                                        <option>2800</option>
                                        <option>3200</option>
                                        <option>3600</option>
                                    </select>
                                </div>
                            </div>
                           
                            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
                                <div className="form-group">
                                    <button type='submit' className="search-button">Search</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</div>

    </div>
</div>


                    </>
  )
}

export default Search