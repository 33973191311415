import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import axios from 'axios'
import {  CURRENCY, FormatNumber, ImagesUrl, ServerUrl,  config } from '../components/Includes';
import { timeSince } from '../components/GlobalFunction';
const PopularPost = () => {

    const property  = useSelector((state:any) => state.rootReducer.property);


    const [content, setContent] = useState([]as any)
    const [title, setTitle] = useState('')
    const fetchContent = async()=>{
        let layout ='Product Layout 1';

        let url = ServerUrl+'/collection/show_one/'+layout;
          await  axios.get(url,  config).then((result:any)=>{

            if(result.data.data&&result.data.data.length!==0){
            

                try{

                    const item = JSON.parse(result.data.data.item_list)
                setTitle(result.data.data.title)


            let list:any = []
            item.map((item:any, id:number)=>
            
            
            list.push({
                code:item.code, 
                key:id, 
                publish:getItemName(item.code, 'publish'), 
                headline:getItemName(item.code, 'headline'),
                cover_image:getItemName(item.code, 'cover_image'),
                price:getItemName(item.code, 'price'),
                address:getItemName(item.code, 'address'),
                bedroom:getItemName(item.code, 'bedroom'),
                bathroom:getItemName(item.code, 'bathroom'),
                room:getItemName(item.code, 'room'),
               floor:getItemName(item.code, 'floor'),
               size:getItemName(item.code, 'size'),
               rating:getItemName(item.code, 'rating'),
                garage:getItemName(item.code, 'garage'),
                slug:getItemName(item.code, 'slug'),
                offer_type:getItemName(item.code, 'offer_type'),
                created_at:getItemName(item.code, 'created_at'),
            }))
          


            setContent(list)

                }catch(e){

                }
            
            }
            })
            .catch((error)=>{
           
            })
          }



          const getItemName =(code:string, field:string)=>{
            const result = property&&property.filter((item:any)=>item.code===code)
            const answer = result.length!==0?result[0][field]:''
            return String(answer)
        }

    
       useEffect(()=>{
        fetchContent()
       }, [property])   



  return (


    <div className="widget popular-posts">
    <h3 className="sidebar-title">Popular Posts</h3>
    <div className="s-border"></div>
    <div className="m-border"></div>




    
    {content&&content.map((list:any, index:number)=> <div key={index} className="media">
        <div className="media-left">
            <img className="media-object"  onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.cover_image}`} alt="sub-properties" />
        </div>
        <div className="media-body align-self-center">
            <h3 className="media-heading">
                <a href={'/property/'+list.slug}>{list.headline}</a>
            </h3>
            <p>{timeSince(new Date(list.created_at))} | {CURRENCY+FormatNumber(list.price)}</p>
        </div>
    </div>)}


</div>


  )
}

export default PopularPost