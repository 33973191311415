import React from 'react'
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'

const Faq = () => {
  return (<Template>
<Breadcrumb />


<div className="faq content-area">
    <div className="container">
        <div className="main-title">
            <h1>Frequently Asked Questions</h1>
            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod</p>
        </div>
        <div className="row">
            <div className="col-lg-12">
                <div className="tabbing tabbing-box mb-30">
                    <ul className="nav nav-tabs" id="carTab" role="tablist">
                        <li className="nav-item">
                            <a className="nav-link active show" id="one-tab" data-toggle="tab" href="#one" role="tab" aria-controls="one" aria-selected="false">General Information</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="two-tab" data-toggle="tab" href="#two" role="tab" aria-controls="two" aria-selected="false">Extra Feature</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" id="three-tab" data-toggle="tab" href="#three" role="tab" aria-controls="three" aria-selected="true">Properties Overview</a>
                        </li>
                    </ul>
                    <div className="tab-content" id="carTabContent">
                        <div className="tab-pane fade active show" id="one" role="tabpanel" aria-labelledby="one-tab">
                            <div id="faq" className="faq-accordion">
                                <div className="card m-b-0">
                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse7">
                                            What do you mean by an End Product?
                                        </a>
                                    </div>
                                    <div id="collapse7" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse8">
                                            Where do I find my Purchase or License code?
                                        </a>
                                    </div>
                                    <div id="collapse8" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse9">
                                            Do I need to buy a licence for each site?
                                        </a>
                                    </div>
                                    <div id="collapse9" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-header bd-none">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse10">
                                            Is my license transferable?
                                        </a>
                                    </div>
                                    <div id="collapse10" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse11">
                                            Do I need to buy a licence for each site?
                                        </a>
                                    </div>
                                    <div id="collapse11" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="card-header bd-none mb-0">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse12">
                                            Is my license transferable?
                                        </a>
                                    </div>
                                    <div id="collapse12" className="card-block collapse">
                                        <div className="foq-info pad-b-0">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="two" role="tabpanel" aria-labelledby="two-tab">
                            <div id="faq2" className="faq-accordion">
                                <div className="card m-b-0">
                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq2" href="#collapse13">
                                            Do I need to buy a licence for each site?
                                        </a>
                                    </div>
                                    <div id="collapse13" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card-header bd-none">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq2" href="#collapse14">
                                            Is my license transferable?
                                        </a>
                                    </div>
                                    <div id="collapse14" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq2" href="#collapse15">
                                            Do I need to buy a licence for each site?
                                        </a>
                                    </div>
                                    <div id="collapse15" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card-header bd-none mb-0">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq2" href="#collapse16">
                                            Is my license transferable?
                                        </a>
                                    </div>
                                    <div id="collapse16" className="card-block collapse">
                                        <div className="foq-info pad-b-0">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade " id="three" role="tabpanel" aria-labelledby="three-tab">
                            <div id="faq3" className="faq-accordion">
                                <div className="card m-b-0">
                                    <div className="card-header bd-none">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse17">
                                            Is my license transferable?
                                        </a>
                                    </div>
                                    <div id="collapse17" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card-header">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse18">
                                            Do I need to buy a licence for each site?
                                        </a>
                                    </div>
                                    <div id="collapse18" className="card-block collapse">
                                        <div className="foq-info">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>

                                    <div className="card-header bd-none mb-0">
                                        <a className="card-title collapsed" data-toggle="collapse" data-parent="#faq" href="#collapse19">
                                            Is my license transferable?
                                        </a>
                                    </div>
                                    <div id="collapse19" className="card-block collapse">
                                        <div className="foq-info pad-b-0">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec luctus tincidunt aliquam. Aliquam gravida massa at sem vulputate interdum et vel eros. Maecenas eros enim, tincidunt vel turpis vel, dapibus tempus nulla. Donec vel nulla dui. Pellentesque sed ante sed ligula hendrerit condimentum. Suspendisse rhoncus fringilla ipsum quis porta. Morbi tincidunt viverra pharetra. Vestibulum vel mauris et odio lobortis laoreet eget eu magna. Proin mauris erat, luctus at nulla ut, lobortis mattis magna. Morbi a arcu lacus. Maecenas tristique velit vitae nisi consectetur, in mattis diam sodales. Mauris sagittis sem mattis justo bibendum, a eleifend dolor facilisis. Mauris nec pharetra tortor, ac aliquam felis. Nunc pretium erat sed quam consectetur fringilla.</p>
                                            <hr />
                                            <span>
                                                Was this answer helpful?
                                                <a href="#" className="yes">Yes <i className="fa fa-thumbs-o-up"></i></a>
                                                <a href="#" className="no">No <i className="fa fa-thumbs-o-down"></i></a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

  </Template>
  )
}

export default Faq