import React from 'react'
import { ImagesUrl } from './Includes';

function Navbar(props:any) {

    let profile = props.profile;

    const pathname = window.location.pathname

  return (
<header className="main-header">
    <div className="container">
        <nav className="navbar navbar-expand-lg navbar-light">
            <a className="navbar-brand logos" href="/">
                <img src={ImagesUrl+'/'+profile.image_url} alt="logo" />
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav header-ml">
                    <li className={pathname==='/'?"nav-item active":"nav-item "} >
                        <a className="nav-link " href="/"  >
                            Home
                        </a>
                    </li>
                    <li className={pathname==='/about'?"nav-item active":"nav-item "}>
                        <a className="nav-link " href="/about"  >
                            About Us
                        </a>
                    </li>

                    <li className={pathname==='/property'?"nav-item active":"nav-item "}>
                        <a className="nav-link " href="/property"  >
                            Properties
                        </a>
                    </li>


                    <li className={pathname==='/services'?"nav-item active":"nav-item "}>
                        <a className="nav-link " href="/services"  >
                            Services
                        </a>
                    </li>
 <li className={pathname==='/contact'?"nav-item active":"nav-item "}>
                        <a className="nav-link " href="/contact"  >
                            Contact
                        </a>
                    </li>
             
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <a href="/investment" className="nav-link link-btn">Investment</a>
                    </li>
                </ul>
            </div>
        </nav>
    </div>
</header>
  )
}

export default Navbar