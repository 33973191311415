
export const ImagesUrl = process.env.REACT_APP_PUBLIC_IMAGE_URL;
export const ServerUrl = process.env.REACT_APP_SERVER_URL;
export const APP_NAME = process.env.REACT_APP_NAME;
export const APP_KEY = process.env.REACT_APP_KEY;
export const CURRENCY ='$';// process.env.REACT_APP_CURRENCY;
export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const INVESTMENT_URL ='https://investors.'+process.env.REACT_APP_MAIN_URL;

export const Token:any = 'token'

export const config = {
    headers: { 'content-type': 'multipart/form-data',
    'Authorization': `Bearer ${Token}`}
} 


export const FormatNumber = (num:Number)=>{
    let newNumber = Number(num)
    return  newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}