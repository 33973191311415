import React from 'react'

const Error404 = () => {
  return (
    <div className="page-404">
    <div className="container">
        <div className="row">
            <div className="col-xl-6 col-lg-7 col-md-12">
                <h1>404</h1>
            </div>
            <div className="col-xl-6 col-lg-5 col-md-12">
                <div className="info">
                    <h4>Ooops, This Page Could Not Be Found!</h4>
                    <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
                    <div className="hr"></div>
                    <p>Please try searching again</p>
                    <div className="clearfix search-form">
                        <form className="form-inline" action="#" method="GET">
                            <input type="text" className="form-control" placeholder="Search For Page" />
                            <button type="submit" className="btn btn-color">Search</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Error404