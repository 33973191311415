import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import axios from 'axios'
import { ServerUrl, config } from '../components/Includes';

import { useSelector } from 'react-redux'
const Interested = (props:any) => {

    const profile  = useSelector((state:any) => state.rootReducer.profile);
    const [notice, setNotice] = useState({
        isLoading : false
	});
    const [comment, setComment]= useState({
        fullname:'',
        telephone:'',
        email:'',
        property_code:props.slug,
        message:'Hello, I am interested in ...',
        how_you_hear:'',
        num1:'',
        num2:'',
        answer:'',
        puzzle:''
     })

     const handleCall = ()=>{
        window.open('tel:'+profile.telephone)
     }
   const handleReset=()=>{
    var num1 = Math.floor(Math.random() * 9)
    var num2 = Math.floor(Math.random() * 9)
    var answer = num1+num2
   
       setComment({
        fullname:'',
        telephone:'',
        email:'',
        property_code:props.slug,
        message:'Hello, I am interested in ...',
        how_you_hear:'',
        num1:String(num1), 
        num2:String(num2), 
        answer:answer.toString(),
        puzzle:''
       })
   }
 const [errors, setErrors] = useState({
    message:'',
    fullname:'',
    telephone:'',
    email:'',
    how_you_hear:'',
        puzzle:'',
    errorMessage:'',
    successMessage:''
 })

     const handleChange = (event:ChangeEvent<HTMLInputElement>) =>{		
        let {name, value} = event.target;	
        setComment({...comment, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeSelect = (event:ChangeEvent<HTMLSelectElement>) =>{		
        let {name, value} = event.target;	
        setComment({...comment, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const generatePuzzle =()=>{
        var num1 = Math.floor(Math.random() * 9)
        var num2 = Math.floor(Math.random() * 9)
        var answer = num1+num2
        setComment({...comment, num1:String(num1), num2:String(num2), answer:answer.toString()})
    
} 

const handleChangeArea = (event:ChangeEvent<HTMLTextAreaElement>) =>{	
    let {name, value} = event.target;	
    setComment({...comment, [name] : value });
    setErrors({...errors, [name]:''});
 }


    const submit =(event: FormEvent<HTMLFormElement>)=>{
        event.preventDefault();
        let formIsValid = true;
        let errorLog ={...errors}
			let msg ='This field is required';

            let email = comment.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i); 
            if(!email){
                formIsValid = false;
                errorLog.email ='Please enter valid email address';
            }


      if(!comment.fullname){ 
        formIsValid = false;
            errorLog.fullname =msg
		}  
       
        if(!comment.telephone){ 
            formIsValid = false;
                errorLog.telephone =msg
            }  


        if(!comment.message){ 
            formIsValid = false;
            errorLog.message =msg
		} 



        if(!comment.puzzle){ 
            formIsValid = false;
            errorLog.puzzle=msg

            setErrors({...errors, puzzle:msg})
		}else if(Number(comment.puzzle) !== Number(comment.answer)){
            formIsValid = false;
            errorLog.puzzle ='Answer provided to puzzle is not correct';
          
        }

        if(!formIsValid){
            setErrors(errorLog)
        }
        


            if (formIsValid) {

               setNotice({...notice,  isLoading: true})     

       
            const fd = new FormData();
        Object.entries(comment).forEach(([key, value]) => {
                fd.append(key, String(value));
            }); 


 let url = ServerUrl+'/contact/add';
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.status === 200){
            setErrors({...errors, successMessage: response.data.message})
         
       setTimeout(()=>{
        window.location.reload()
       }, 3000)
                  } else{

                    
         setErrors({...errors, errorMessage: response.data})
                   
                  }   
              })
              .catch((error)=>{
                 setErrors({...errors, errorMessage:error.message})
              }).finally(()=>{
                setNotice({...notice,  isLoading: false})
                handleReset()  
              }) 
        }
    }

   
    useEffect(()=>{
        generatePuzzle();
        }, [])

  return (
   
    <div className="sidebar widget advanced-search ">
    <h3 className="sidebar-title">Are you Interested?</h3>
    <div className="s-border"></div>
    <div className="m-border"></div>

{errors.successMessage?
    <div className="alert alert-success" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <strong>Success!</strong> {errors.successMessage}
                </div>:[]}

                {errors.errorMessage?
    <div className="alert alert-danger" role="alert">
                    <button type="button" className="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">×</span></button>
                    <strong>Errors!</strong> {errors.errorMessage}
                </div>:[]}


    <form method="#" onSubmit={submit}>
        <div className="form-group">

            <input type="text"  name='fullname' placeholder='Full Name*'   className={errors.fullname ? ' form-control formerror' : 'form-control '} value={comment.fullname} onChange={handleChange} required />
          
        </div>
        <div className="form-group">
            <input type="text"  name='telephone' placeholder='Phone Number*' className={errors.telephone ? ' form-control formerror' : 'form-control '} value={comment.telephone} onChange={handleChange} required />
          
        </div>
        <div className="form-group">
            <input type="email"  name='email' placeholder='Email Address*' className={errors.email ? ' form-control formerror' : 'form-control '} value={comment.email} onChange={handleChange} required />
          
        </div>
        <div className="form-group">
        <textarea  rows={15} placeholder='Message'
        name='message'
        value={comment.message} onChange={handleChangeArea} className={errors.message ? ' formerror form-control' : 'form-control'} required  >


        </textarea>
        </div>
        <div className="form-group">
            <select className="selectpicker search-fields"  name="how_you_hear" onChange={handleChangeSelect} value={comment.how_you_hear}>
                <option selected >How do you hear about us</option>
                <option value="Friend">Friend</option>
                <option value="Google">Google</option>
                <option value="Email">Email</option>
                <option value="Social Media">Social Media</option>
            </select>
                            </div>


        <div className="form-group">
            <input type="text" className={errors.puzzle ? ' form-control formerror' : 'form-control '}  id="puzzle"   name="puzzle" role={'presentation'}  autoComplete="off" value={comment.puzzle} placeholder={comment.num1 + ' + '+ comment.num2 + '  =?' } onChange={handleChange} />
          
        </div>


        <div className="form-group mb-0">

        {notice.isLoading? <button className="search-button" type="button" disabled><span className="spinner-border spinner-border-sm mr-2" ></span>Loading...</button>:

            <button className="search-button" type='submit'>Send Message</button>}&nbsp;
            <button type='button' className="search-button bg-primary" onClick={handleCall}>Call</button>
        </div>
    </form>
</div>
  )
}

export default Interested