import React from 'react'

const Counter = () => {
  return (
    
<div className="counters">
    <div className="container">
        <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="media counter-box">
                    <div className="media-left">
                        <i className="flaticon-tag"></i>
                    </div>
                    <div className="media-body">
                        <h1 className="counter">967</h1>
                        <p>Listings For Sale</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="media counter-box">
                    <div className="media-left">
                        <i className="flaticon-business"></i>
                    </div>
                    <div className="media-body">
                        <h1 className="counter">1276</h1>
                        <p>Listings For Rent</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="media counter-box">
                    <div className="media-left">
                        <i className="flaticon-people"></i>
                    </div>
                    <div className="media-body">
                        <h1 className="counter">396</h1>
                        <p>Our Agents</p>
                    </div>
                </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
                <div className="media counter-box">
                    <div className="media-left">
                        <i className="flaticon-people-1"></i>
                    </div>
                    <div className="media-body">
                        <h1 className="counter">177</h1>
                        <p>Computer Brokers</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


  )
}

export default Counter