import React from 'react'
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'

const Services = () => {
  
  return (<Template>

<Breadcrumb title='Services' />

<div className="services-3 content-area">
    <div className="container">
        <div className="main-title">
            <h1>Professional Services</h1>
      
        </div>
        <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-info-2">
                    <div className="icon">
                        <i className="flaticon-lock"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Security
                        </h3>
                        <p>Security services are an important component of keeping people safe in public spaces. Whether in a school or office building.</p>
                        <h4>01</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-info-2">
                    <div className="icon">
                        <i className="flaticon-technology"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Air conditioning
                        </h3>
                        <p>To Clean or replace your air conditioning system's filter or filters every month or two during the cooling season. we can be of help</p>
                        <h4>02</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-info-2">
                    <div className="icon">
                        <i className="flaticon-apartment"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Apartments Clean
                        </h3>
                        <p>We are  professional apartment cleaning services every week or once in a while for proven, personalized cleaning that ensures the results you deserve.</p>
                        <h4>03</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-info-2">
                    <div className="icon">
                        <i className="flaticon-coins"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Commercial
                        </h3>
                        <p>Our Commercial service means service to laundromats, theaters, stores, offices, general private and public businesses and gaming establishments. </p>
                        <h4>04</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-info-2">
                    <div className="icon">
                        <i className="flaticon-people-2"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Gym
                        </h3>
                        <p>Complete strength or resistance training exercises for the remaining bulk of the workout. Choose 4-6 exercises and perform 3-4 sets of 8-12 reps</p>
                        <h4>05</h4>
                    </div>
                </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="service-info-2">
                    <div className="icon">
                        <i className="flaticon-people"></i>
                    </div>
                    <div className="service-detail">
                        <h3>
                            Trusted Agents
                        </h3>
                        <p>Our house helps are good with general home keeping, laundry, cleaning & assisting with kid where necessary. kindly call. Make an offer</p>
                        <h4>06</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



  </Template>
  )
}

export default Services