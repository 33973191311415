import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react'

import UsePagination from '../components/pageNumber';
import axios from 'axios';
import Template from '../components/Template'
import Breadcrumb from '../components/Breadcrumb'

import { useDispatch, useSelector } from 'react-redux';
import PopularPost from '../layouts/PopularPost';
import { timeSince } from '../components/GlobalFunction';
import { CURRENCY, FormatNumber, ImagesUrl } from '../components/Includes';

const SearchResult = () => {

    const profile  = useSelector((state:any) => state.rootReducer.profile);
    const property  = useSelector((state:any) => state.rootReducer.property);
    const category  = useSelector((state:any) => state.rootReducer.category);

    const [filterContent, setFilterContent] = useState([] as any);
    const [productList, setProductList] = useState([] as any);

    

    let [page, setPage] = useState(1);

  //maximum item perpage
  const PER_PAGE = 6;

  //use custom pagination for the logic
  const dataList = UsePagination(property, PER_PAGE);

  

  var pages = Array.from(Array(dataList.maxPage).keys()).map(i =>  i+1);

  const [search, setSearch] = useState({
    category:'',
    filterText:'',
    min:0,
    max:1000,
  })

   const handleChange = ( num:number) => {
    setPage(num);
    dataList.jump(num);
  }; 



const handleSearch =(event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    setSearch({...search, [name]:value});
  
  
    //filter product list base on name and store result on filter content
    if(value!==''){
        const filteredItems = productList.filter(
            (item:any) => item.name.toLowerCase().includes(value.toLowerCase()),
        );
  
        setFilterContent(filteredItems)
    }else{
        setFilterContent(productList)
    } 
  
  }




  return ( <Template>


<Breadcrumb title='Properties Listing' />

   

<div className="properties-section content-area">
    <div className="container">
        <div className="row">
            <div className="col-lg-8 col-md-12">
                <div className="option-bar">
                    <div className="float-left">
                        <h4>
                            <span className="heading-icon">
                                <i className="fa fa-th-list"></i>
                            </span>
                            <span className="title-name">Properties List</span>
                        </h4>
                    </div>
                    <div className="float-right cod-pad">
                        <div className="sorting-options">
                            <select className="sorting">
                                <option>New To Old</option>
                                <option>Old To New</option>
                                <option>Properties (High To Low)</option>
                                <option>Properties (Low To High)</option>
                            </select>
                            <a href="/search" className="change-view-btn active-view-btn"><i className="fa fa-th-list"></i></a>
                            <a href="/property" className="change-view-btn"><i className="fa fa-th-large"></i></a>
                        </div>
                    </div>
                </div>
                {property&& dataList.currentData().map((list:any, index:number)=>  <div className="property-box-2" key={index}>
                    <div className="row">
                       
                       
                       
                        <div className="col-lg-5 col-md-5 col-pad">
                            <a href={'/property/'+list.slug}className="property-img">
                                <img  onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.cover_image}`} alt="properties" className="img-fluid" />
                                <div className="listing-badges">
                                    <span className="featured">Featured</span>
                                    <span className="listing-time">{list.offer_type}</span>
                                </div>
                                <div className="price-box">{CURRENCY+FormatNumber(list.price)}</div>
                            </a>
                        </div>
                        <div className="col-lg-7 col-md-7 col-pad">
                            <div className="detail">
                                <h3 className="title">
                                    <a href={'/property/'+list.slug}>{list.headline}</a>
                                </h3>
                                <p className="location">
                                    <a href={'/property/'+list.slug}>
                                        <i className="flaticon-location"></i>{list.address}
                                    </a>
                                </p>
                                <ul className="facilities-list clearfix">
                                    <li>
                                        <i className="flaticon-furniture"></i>
                                        <span>{list.bedroom} Beds</span>
                                    </li>
                                    <li>
                                        <i className="flaticon-holidays"></i>
                                        <span>{list.bathroom} Baths</span>
                                    </li>
                                    <li>
                                        <i className="flaticon-square"></i>
                                        <span>{list.size}</span>
                                    </li>
                                    <li>
                                        <i className="flaticon-vehicle"></i>
                                        <span>{list.garage} Garage</span>
                                    </li>
                                    <li>
                                        <i className="flaticon-monitor"></i>
                                        <span>{list.room} Room</span>
                                    </li>
                                    <li>
                                        <i className="flaticon-window"></i>
                                        <span>{list.floor} Floor</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer clearfix">
                                <div className="pull-left days">
                                    <a><i className="fa fa-user"></i> Admin</a>
                                </div>
                                <div className="pull-right">
                                    <a><i className="flaticon-time"></i> {timeSince(new Date(list.created_at))}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)}


                {property&&property.length!==0?
      
      
      <div className="pagination-box hidden-mb-45 text-center">
      
      
                <nav aria-label="Page navigation example" className="d-flex justify-content-center mt-3">
                  <ul className="pagination">
      
      
                  <li className={page === 1 ? ' page-item disabled' : 'page-item'}> <a className="page-link" href="#!" onClick={(e) =>handleChange(page - 1)}><span aria-hidden="true">&laquo;</span></a></li>
      
      
                  {pages.map((item:number, index:number) =>
              <li key={index} className='page-item '><a className={page===item?' page-link  active':'page-link '} href="#!" onClick={(e) =>handleChange(item)}>{item}</a></li>    
                   )}
      
      
      <li className={page === dataList.maxPage  ? ' page-item disabled' : 'page-item'}  ><a className="page-link" href="#!" onClick={(e) =>handleChange(page + 1)} aria-label="Next"><span aria-hidden="true">&raquo;</span></a></li>  
      
                  </ul>
                </nav></div>:[]}
            </div>



            <div className="col-lg-4 col-md-12">
                <div className="sidebar-right">


                <div className="sidebar widget advanced-search">
                        <h3 className="sidebar-title">Advanced Search</h3>
                        <div className="s-border"></div>
                        <div className="m-border"></div>


                        <form method="GET">
                            <div className="form-group">
                                <div className="">
                                    
                                    <select className="selectpicker search-fields" name="property-sdtatus" tabIndex={-98}>
                                    <option>Property Status</option>
                                    <option>For Sale</option>
                                    <option>For Rent</option>
                                </select>
                                
                                
                                </div>
                            </div>
                            <div className="form-group">
                                <div className="dropdown 
                                search-fields">
                                    
                                    <select className="selectpicker search-fields" name="property-type" tabIndex={-98}>
                                    <option>Property Type</option>
                                    <option>Apartments</option>
                                    <option>Houses</option>
                                    <option>Commercial</option>
                                    <option>Garages</option>
                                </select>
                                
                                
                                </div>
                            </div>
                            <div className="form-group">
                                <div >
                                    <select className="selectpicker search-fields" name="commercial" tabIndex={-98}>
                                    <option>Commercial</option>
                                    <option>Residential</option>
                                    <option>Land</option>
                                    <option>Hotels</option>
                                </select></div></div>
                           
                            <div className="form-group">
                                <div className="dropdown bootstrap-select search-fields"><select className="selectpicker search-fields" name="location" tabIndex={-98}>
                                    <option>location</option>
                                    <option>New York</option>
                                    <option>Bangladesh</option>
                                    <option>India</option>
                                    <option>Canada</option>
                                </select>
                                
                                
                                </div></div>
                          
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div ><select className="selectpicker search-fields" name="bedrooms" tabIndex={-98}>
                                            <option>Bedrooms</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                        
                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="dropdown bootstrap-select search-fields"><select className="selectpicker search-fields" name="bathroom" tabIndex={-98}>
                                            <option>Bathroom</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="dropdown bootstrap-select search-fields"><select className="selectpicker search-fields" name="balcony" tabIndex={-98}>
                                            <option>Balcony</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                        
                                        
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <div className="dropdown bootstrap-select search-fields"><select className="selectpicker search-fields" name="garage" tabIndex={-98}>
                                            <option>Garage</option>
                                            <option>1</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                        </select>
                                        
                                        </div>
                                    </div>
                                </div>
                            </div>

                          
                            <div className="form-group mb-0">
                                <button className="search-button">Search</button>
                            </div>
                        </form>
                    </div>


                    <PopularPost />

                    <div className="posts-by-category widget">
                        <h3 className="sidebar-title">Category</h3>
                        <div className="s-border"></div>
                        <div className="m-border"></div>
                        <ul className="list-unstyled list-cat">
                        {category&&category.map((item:any, index:number)=>
                            <li key={index}><a href="#!">{item.title} <span>({item.total})</span></a></li>)}
                        </ul>
                    </div>


                    <div className="widget helping-center">
                        <h3 className="sidebar-title">Helping Center</h3>
                        <div className="s-border"></div>
                        <div className="m-border"></div>
                        <ul className="contact-link">
                            <li>
                                <i className="flaticon-location"></i>
                                {profile.address}
                            </li>
                            <li>
                                <i className="flaticon-technology-1"></i>
                                <a href={`tel:${profile.telephone}`}>
                                    {profile.telephone}
                                </a>
                            </li>
                            <li>
                                <i className="flaticon-envelope"></i>
                                <a href={`mailto:${profile.email_address}`}>
                                {profile.email_address}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> 

    </Template>
  )
}

export default SearchResult