import React, { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'
import axios from 'axios'
import {  CURRENCY, FormatNumber, ImagesUrl, ServerUrl,  config } from '../components/Includes';

const LayoutFour = () => {
    const property  = useSelector((state:any) => state.rootReducer.property);


    const [content, setContent] = useState([]as any)
    const [title, setTitle] = useState('')
    const fetchContent = async()=>{
        let layout ='Product Layout 2';

        let url = ServerUrl+'/collection/show_one/'+layout;
          await  axios.get(url,  config).then((result:any)=>{

            if(result.data.data&&result.data.data.length!==0){
            

                try{

                    const item = JSON.parse(result.data.data.item_list)
                setTitle(result.data.data.title)


            let list:any = []
            item.map((item:any, id:number)=>
            
            
            list.push({
                code:item.code, 
                key:id, 
                publish:getItemName(item.code, 'publish'), 
                headline:getItemName(item.code, 'headline'),
                cover_image:getItemName(item.code, 'cover_image'),
                price:getItemName(item.code, 'price'),
                address:getItemName(item.code, 'address'),
                bedroom:getItemName(item.code, 'bedroom'),
                bathroom:getItemName(item.code, 'bathroom'),
                room:getItemName(item.code, 'room'),
               floor:getItemName(item.code, 'floor'),
               size:getItemName(item.code, 'size'),
               rating:getItemName(item.code, 'rating'),
                garage:getItemName(item.code, 'garage'),
                slug:getItemName(item.code, 'slug'),
                offer_type:getItemName(item.code, 'offer_type'),
                created_at:getItemName(item.code, 'created_at'),
            }))
          


            setContent(list)

                }catch(e){

                }
            
            }
            })
            .catch((error)=>{
           
            })
          }



          const getItemName =(code:string, field:string)=>{
            const result = property&&property.filter((item:any)=>item.code===code)
            const answer = result.length!==0?result[0][field]:''
            return String(answer)
        }

    
       useEffect(()=>{
        fetchContent()
       }, [property])   





  return (<div className="recently-properties content-area-12">
    <div className="container">
        <div className="main-title">
            <h1>{title}</h1>
        </div>
        <div className="row">
              
               
               
                {content&&content.map((list:any, index:number)=> <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                    <div className="property-box-5">
                        <div className="property-photos">
                            <img className="img-fluid" onError={(e)=>{(e.target as HTMLImageElement).onerror = null; (e.target as HTMLImageElement).src =ImagesUrl+'/no.png'}}  src={`${ImagesUrl}/property/${list.cover_image}`} alt="properties" />
                            <div className="date-box">{list.offer_type}</div>
                        </div>
                        <div className="detail">
                            <div className="heading">
                                <h3>
                                    <a  href={'/property/'+list.slug}>{list.headline}</a>
                                </h3>
                                <div className="location">
                                    <a  href={'/property/'+list.slug}>
                                        <i className="fa fa-map-marker"></i>{list.address}
                                    </a>
                                </div>
                            </div>
                            <div className="properties-listing">
                                <span>{list.bedroom} Beds</span>
                                <span>{list.bathroom} Baths</span>
                                <span>{list.size} sqft</span>
                            </div>
                        </div>
                    </div>
                </div>)}
        </div>
    </div>
</div>
  )
}

export default LayoutFour